<template>
  <div>
    <div class="text-heading --prm --w-bold bg-white text-center py-6">
      {{ $t("common.receipt") }}
    </div>
    <div class="form-container px-5 pt-7 pb-15">
      <!-- Receipt item -->
      <div v-for="item in items" :key="item.id" class="bg-w-silver pa-4 mb-3">
        <div>
          <span class="text-title font-weight-bold">
            {{ item.clientChargeType.name }}
          </span>
          <!--<p class="mr-3 text-medium">{{ item.clientChargeType.name }}</p>-->
        </div>
        <v-divider class="my-4" />
        <div class="text-center">
          <v-btn
            elevation="0"
            @click="showPdf(item)"
            outlined
            large
            width="117"
            rounded
            class="bg-white text-medium --prm-light"
            :disabled="!hasPdf(item)"
          >
            {{ $t("memberInfo.publish") }}
          </v-btn>
        </div>
      </div>
      <!-- ----------- -->
      <div class="text-center pt-3 pb-6">
        <v-btn
          elevation="0"
          rounded
          class="hover-secondary bg-white text-medium font-weight-bold px-10"
          @click="loadList()"
          :disabled="!hasMore"
        >
          {{ $t('buttons.viewMore') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import {
  paginationMixin
} from '@/mixins/pagination.mixin'

export default {
  name: 'MemberInfoReceiptList',
  mixins: [paginationMixin],
  methods: {
    getPdfUrl (row) {
      return row.receipt?.pdfFileUrl
    },
    hasPdf (row) {
      return !!this.getPdfUrl(row)
    },
    showPdf (row) {
      if (this.hasPdf(row)) {
        window.open(this.getPdfUrl(row), '_blank').focus()
      }
    },
    async loadList () {
      await this.getPaginatedList((apollo, skip, take) =>
        this.$store.dispatch('myClientChargeList', {
          apollo,
          skip,
          take
        }))
    }
  },
  async mounted () {
    await this.loadList()
  }
}
</script>
